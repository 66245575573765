import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

interface props {
  parameter: string[];
}
const ZipList = (props: props) => {
  return (
    <>
      {props.parameter.map((item, i) => {
        const locationPath = `Car-State-Inspection-California-Near-Zipcode-${item}`;
        return (
          <a href={locationPath} className="btn btn-primary m-2" key={i}>
            Car State Inspection in California Locations Near Zipcode {item}
          </a>
        );
      })}
    </>
  );
};
export default ZipList;
