import { debug } from "console";
import {
  IDistLatLongInZip,
  ILocation,
  Ilatlong,
  ILocationGridProps,
} from "../types/global.typing";
import distance from "../core/utilities/dataUtilities";

const GeoSearchList = ({
  locations,
  uniqueZipLatLong,
  searchText,
  searchDistance,
  latitude,
  longitude,
}: ILocationGridProps) => {
  if (
    searchText.length < 5 &&
    (latitude === undefined || longitude === undefined)
  ) {
    return (
      <main>
        <article>
          <h2>
            California's New Car State Inspection Program: A Comprehensive Guide
            and List of Safety Inspection Locations
          </h2>
          <p>
            California's Car State Inspection program has undergone significant
            changes with the introduction of new legislation aimed at enhancing
            road safety and environmental standards. Understanding the history,
            requirements, and implications of these changes is crucial for both
            vehicle owners and automotive professionals alike. In this article,
            we delve into the details of California's revamped vehicle
            inspection program, exploring its legislative foundation, safety
            inspection criteria, licensing requirements for shops, and the
            evolution of earlier inspection programs.
          </p>
          <p>
            The roots of California's Car State Inspection program can be traced
            back to the state's commitment to road safety and emission control.
            Over the years, various legislative measures have been enacted to
            address these concerns and ensure that vehicles operating on
            California's roads meet certain safety and environmental standards.
          </p>
          <h3>
            California Regulations that Establish the Car State Inspection
            Program
          </h3>
          <p>
            One significant milestone in this journey was the passage of
            Assembly Bill AB 471, which laid the groundwork for the modern Car
            State Inspection program. This legislation, introduced in 2021,
            aimed to streamline and enhance the original Brake &amp; Lamp
            Inspection process by consolidating multiple inspection programs
            into a unified framework.
          </p>
          <p>
            Under AB 471, the California Department of Motor Vehicles (DMV) and
            the Bureau of Automotive Repair (BAR) was tasked with overseeing the
            implementation of the new Car State Inspection program, working in
            collaboration with authorized brake and lamp inspection stations
            across the state and interested organizations. The primary
            objectives of the program were to improve road safety for salvage
            vehicles in the state of California. The program modifies the
            inspection requirements for Brake & Lamp inspections and renames the
            this California Car State Inspection Program to the California
            Vehicle Safety Inspection Program. (CVSIP).
          </p>
          <h3>Conditions Requiring a Car State Inspection:</h3>
          <p>
            In California, vehicles are subject to Car State Inspections under
            certain conditions to ensure they meet minimum safety standards
            before being allowed on the road. Currently only salvage vehicles
            which are vehicles that have been rebuilt or reconstructed are
            required to undergo a thorough safety inspection to ensure they have
            been properly repaired and restored to a safe operating condition.
            This inspection is essential for certifying the roadworthiness of
            salvage vehicles before they are allowed back on the road.
          </p>
          <h3>Shop Licensing Requirements:</h3>
          <p>
            The current Brake &amp; Lamp inspection stations will play crucial
            role in rolling out California's car state inspection program by
            continuing to conduct Brake &amp; Lamp inspections as the
            requirements and licensing are finalized in 2024. The program will
            be rolled out in a phased approach:
          </p>
          <ol>
            <li>
              Current Brake &amp; Lamp station can be enrolled into the program
              when the program starts in Jun of 2024
            </li>
            <li>
              Renewal of the license will require additional critiera to be met
              such as ASE certification. The Brake &amp; Lamp Inspection program
              will be retired in the last quarter of 2024.
            </li>
            <li>
              Compliance with Regulations: Shops must comply with all applicable
              regulations and standards set forth by the California DMV
              regarding vehicle inspections. This includes adhering to
              inspection procedures, maintaining accurate records, and upholding
              the highest standards of integrity and professionalism.
            </li>
          </ol>
          <h3>Evolution from Previous Programs:</h3>
          <p>
            The introduction of California's new car state inspection program
            represents a significant evolution from earlier inspection programs,
            including the brake and lamp inspection program. Under the previous
            system, vehicles were required to undergo separate brake and lamp
            inspections to ensure they met safety standards in these specific
            areas.
          </p>
          <p>
            However, with the implementation of the new unified inspection
            program, the need for separate brake and lamp inspections has been
            eliminated. Instead, comprehensive safety inspections now encompass
            a broader range of vehicle components, including brakes, lights,
            tires, steering, suspension, and more. This streamlined approach not
            only simplifies the inspection process for vehicle owners but also
            ensures a more comprehensive evaluation of overall vehicle safety.
          </p>
          <h3>Summary of the California Safety Inspection Program:</h3>
          <p>
            California's new car state inspection program represents a concerted
            effort to enhance road safety, reduce vehicle emissions, and uphold
            regulatory standards across the state. By understanding the
            legislative foundation, inspection criteria, shop licensing
            requirements, and evolution from previous programs, stakeholders can
            navigate the complexities of the inspection process with confidence.
            Whether you're a vehicle owner seeking to comply with inspection
            requirements or an automotive professional involved in conducting
            inspections, staying informed and proactive is essential for
            ensuring the safety and reliability of vehicles on California's
            roads.
          </p>
        </article>
      </main>
    );
  }

  // get random lat, long from zipcodes, do set thing filtering on zip and pass that to the function getRandomLocationFromZip
  let latLongValue = Array<IDistLatLongInZip>();
  if (searchText.length === 5) {
    latLongValue = uniqueZipLatLong.filter((x) => x.zip === searchText);
  }
  let distResults = locations.filter((location, i) => {
    location.distance = distance(
      searchText.length === 5 ? latLongValue[0].latLong.lat : latitude ?? -1, //39.1145502, //
      searchText.length === 5 ? latLongValue[0].latLong.long : longitude ?? -1, //-77.5813749,
      location.latitude,
      location.longitude
    );

    return location.distance <= searchDistance;
  });

  return (
    <ol className="list-group gradient-list ">
      {/* <div className="col-8">
        <div>Locations</div>
      </div> */}
      {distResults
        .sort((a, b) => a.distance - b.distance)
        .map((location, i) => {
          return (
            <li key={i} className="list-group-item ">
              <div
                className="row"
                itemScope
                itemType="https://schema.org/PostalAddress"
              >
                <h3
                  className="border border-2 rounded-5 text-center p-3"
                  itemProp="name"
                  itemScope
                  itemType="https://schema.org/LocalBusiness"
                >
                  {location.name}
                </h3>
                <div itemProp="address" className="adr">
                  <div itemProp="streetAddress" className="street-address col">
                    {location.street}
                  </div>
                  <span itemProp="addressLocality" className="locality col">
                    {location.city}
                    {", "}
                  </span>
                  <span itemProp="addressRegion" className="region col">
                    VA{"  "}
                  </span>
                  <span itemProp="postalCode" className="postal-code col">
                    {location.zip}
                  </span>
                  <div itemProp="telephone">
                    {" "}
                    <a href={"tel:" + location.phone} className="tel col">
                      {location.phone}
                    </a>
                  </div>
                </div>
                <div className="col distance">
                  {location.distance.toFixed(2)}
                  {" miles away"}
                </div>
              </div>
            </li>
          );
        })}
    </ol>
  );
};

export default GeoSearchList;
