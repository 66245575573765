import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";

interface ISearchText {
  value: string;
  zips: Array<string>;
  distanceValue: number;
  //handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (e: string) => void;
  handleDistanceChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SearchBox = ({
  value,
  zips,
  distanceValue,
  handleChange,
  handleDistanceChange,
}: ISearchText) => {
  const [singleSelections, setSingleSelections] = useState([]);

  return (
    <>
      <div>
        <h2 className="text-center midnight-text d-none d-md-block">
          Need a car state inspection in California? Allow geolocation to find a
          Car State Inspection shop in California near your current location, or
          find one near your selected zip code. <br /> It&apos;s just that easy.
        </h2>
      </div>
      <Col className="col-xs-6 col-sm-6 col-md-2">
        <Form.Group>
          <Form.Label
            id="ziplabel"
            className="text-capitalize font-weight-bold"
          >
            Select Zip:
          </Form.Label>
          <Typeahead
            id="basic-typeahead-single"
            labelKey="name"
            options={zips}
            placeholder="Choose a zipcode..."
            selected={singleSelections}
            onChange={(selected) => {
              const l = document.getElementById("ziplabel");
              if (l != null) {
                l.textContent = `Select Zip: ${selected[0].toString()}`;
              }
              handleChange(selected[0].toString());
            }}
          />
        </Form.Group>
      </Col>
      <Col className="col-xs-6 col-sm-6 col-md-1">
        <Form.Group>
          <Form.Label>Distance</Form.Label>
          <Form.Select autoComplete="off" onChange={handleDistanceChange}>
            <option>10</option>
            <option>15</option>
            <option>25</option>
          </Form.Select>
        </Form.Group>
      </Col>
    </>
  );
};

export default SearchBox;
