import { ILocation, IDistLatLongInZip } from "../../types/global.typing";

const distance = function (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  //const r = 6371; // km
  const r = 3958.8; // miles
  const p = Math.PI / 180;

  const a =
    0.5 -
    Math.cos((lat2 - lat1) * p) / 2 +
    (Math.cos(lat1 * p) *
      Math.cos(lat2 * p) *
      (1 - Math.cos((lon2 - lon1) * p))) /
      2;
  return 2 * r * Math.asin(Math.sqrt(a));
};

export default distance;

// using a distinct list of zips, pull one location out of each zipcode and return the array.  Use this array to find the lat,long when zip is used
const generateUniqueZipLatLong = (
  zips: string[],
  lstLocations: ILocation[]
): IDistLatLongInZip[] => {
  let x = new Array<IDistLatLongInZip>();
  zips.forEach((zip) => {
    let zipSet = [
      ...new Set(
        lstLocations
          .map((x) => ({
            zip: x.zip,
            latLong: { lat: x.latitude, long: x.longitude },
          }))
          .filter((a) => a.zip === zip)
      ),
    ];

    let p = Math.floor(Math.random() * (zipSet.length - 1));
    x.push({
      zip: zipSet[p] === undefined ? "" : zipSet[p].zip,
      latLong: {
        lat: zipSet[p] === undefined ? -1 : zipSet[p].latLong.lat,
        long: zipSet[p] === undefined ? -1 : zipSet[p].latLong.long,
      },
    });
  });

  return x;
};

export { generateUniqueZipLatLong };
