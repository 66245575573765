import { Info } from "react-bootstrap-icons";
import { IBlogPostProps } from "../types/global.typing";

const BlogList = ({ blogposts }: IBlogPostProps) => {
  if (blogposts.length === 0) {
    return <div>No Posts</div>;
  }
  return (
    <>
      {blogposts.map((x, i) => {
        {
          return (
            <div key={i} className="blog row mb-2">
              <div className="col ">
                <div className="blogrow row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <strong className="d-inline-block mb-2 text-primary-emphasis">
                      {x.topic}
                    </strong>
                    <h3 className="mb-0">{x.title}</h3>
                    <div className="mb-1 text-body-secondary">
                      {x.postCreateDate}
                    </div>
                    <p className="card-text mb-auto">{x.postSlug}</p>
                    <a
                      href={x.postUrl}
                      target="_blank"
                      className="icon-link gap-1 icon-link-hover stretched-link"
                    >
                      Continue reading
                      <svg className="bi"></svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default BlogList;
